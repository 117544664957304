<template>
  <div v-if="dialog">
    <v-dialog :value="dialog" persistent width="60%" style="position: absolute; bottom: 0;">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              !videoData._id
                ? $t("title.create_video_url")
                : $t("title.update_video_url")
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="loading" disabled text @click="validate">
              {{ !videoData._id ? $t("button.create") : $t("button.update") }}
            </v-btn>
            <v-btn v-else text @click="validate">
              {{ !videoData._id ? $t("button.create") : $t("button.update") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <Loading :visible="loading" /> -->
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-text-field
                  v-model="videoData.server_id"
                  :label="$t('input.server_id')"
                  :rules="rules.server_id"
                  type="number"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="videoData.video_url"
                  :label="$t('input.video_url')"
                  :rules="rules.video_url"
                  type="text"
                ></v-text-field>
              </v-row>

            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
button {
}
</style>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          server_id: [(v) => !!v || "Server Id is required"],
          video_url: [(v) => !!v || "Video Url is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    videoData: {
      server_id:'',
      video_url:''
     },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      
    };
  },
  watch: {
    
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      }
    },
  },
  
};
</script>


